@import 'theme.variables.scss';
.pageTitle {
  margin: 0 0 0 1%;
}

.user-name {
  background: $SecondaryColor !important;
  margin-right: 10px;
  font-size: medium;
  width: 35px !important;
  height: 35px !important;
  color: #fff !important;
}
.user-account-menu {
  &:before {
    content: '';
    border-bottom: 10px solid #fff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    position: absolute;
    top: -10px;
    right: 16px;
    z-index: 10;
  }
  padding: 0px !important;
  .MuiListItemIcon-root {
    min-width: 30px !important;
  }
  .MuiListItemText-root {
    margin-right: 10px;
  }
  .MuiListItem-root {
    border-bottom: 1px solid #ddd;
    &:last-child {
      border-bottom: none;
    }
  }
  .selected {
    background: $SecondaryColor;
    color: #fff;
    .MuiListItemIcon-root {
      color: #fff;
    }
  }
}
#user-profile {
  margin-right: 0px;
  .MuiButton-root {
    padding: 0 0 0 10px !important;
  }
  .avatar-icon {
    background: $SecondaryColor !important;
    width: 35px !important;
    height: 35px !important;
    color: $textWhite !important;
    font-size: medium;
  }
  .avatar-linked-acct-icon {
    background: $linkedAccountColor !important;
    width: 35px !important;
    height: 35px !important;
    color: $textWhite !important;
    font-size: medium;
  }
}

.side-menu{
  .MuiListItem-root{
    line-height: 1;
    min-height: 20px;
    font-weight: bold;
    font-size: 0.85rem !important;
    color: $PrimaryColor;
  }
}