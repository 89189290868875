@import '../../theme.variables.scss';
.billing-wrapper {
  padding: 0;
  overflow: hidden;
}
.billtabWrapper {
  height: calc(100vh - 38vh);
  overflow: auto;
  padding: 15px;
  padding-top: 0;
}
.MuiTab-root {
  text-transform: none !important;
  font-size: 0.775rem;
  padding: 9px !important;
}
@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 130px !important;
  }
}
.tab-header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h5 {
    color: $PrimaryColor;
  }
}
.tab-content {
  margin-bottom: 20px;
}
.MuiTabScrollButton-root {
  width: 15px !important;
}
.due_amount {
  h3,
  h4 {
    margin: 0;
  }
  h3 {
    color: $textError;
  }
}
.recent-payments-list {
  ul {
    list-style: none;
    padding: 0;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding: 5px 0 5px 0 !important;
      &:last-child {
        border-bottom: none !important;
      }
    }
    .payment_date {
      width: 20%;
      color: $PrimaryColor;
      text-align: center;
      padding: 0px 8px;
      h5 {
        margin: 0px !important;
      }
    }
    .payment_info {
      width: 80%;
      h5,
      p {
        margin: 0px !important;
      }
      h5 {
        color: $SecondaryColor;
      }
    }
  }
}

.trans-status {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 15%;
  text-align: center;
  .MuiSvgIcon-root {
    font-size: 120px;
  }
  .success {
    color: green;
  }

  .error {
    color: red;
  }

  h2 {
    margin-bottom: 0;
  }

  p {
    font-size: 16px;
    font-weight: bold;
  }

  .MuiButton-root {
    margin-top: 25px;
  }
}
